/**
 * Module dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

import CategoryTrendEntry from '../category-trend-entry/category-trend-entry';
import { useStaticProps } from '../context/static-props';

/**
 * CategoryTrendContainer Component
 */
const CategoryTrendContainer = (props) => {
  const namespace = 'ui-category-trends-container';
  const { trends_categories, hasCarousel } = props;

  const { deviceType, jsDisabled, webp: supportWebP } = useStaticProps();

  const containerViewClass = 'container';
  const viewTypeClass = containerViewClass;
  const carouselClass = hasCarousel ? 'carousel' : '';

  return (
    <section className={`${namespace} ${deviceType}`}>
      <div className={`${namespace}-${deviceType}`}>
        <h2>{trends_categories.title}</h2>
        <div className={`${namespace}-searches ${viewTypeClass} ${carouselClass}`}>
          {trends_categories.trends.map((trendEntry) => (
            <div key={`category-entry-container-${viewTypeClass}-${trendEntry.keyword}`} className="item">
              <CategoryTrendEntry
                categoryTrend={trendEntry}
                deviceType={deviceType}
                supportWebP={supportWebP}
                preload
                jsDisabled={jsDisabled}
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

CategoryTrendContainer.propTypes = {
  hasCarousel: PropTypes.bool,
  trends_categories: PropTypes.shape({
    title: PropTypes.string,
    trends: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        images: PropTypes.arrayOf(
          PropTypes.shape({
            description: PropTypes.string.isRequired,
            url: PropTypes.string.isRequired,
          }),
        ).isRequired,
        keyword: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ),
  }),
};

/**
 * Inject i18n context as props into CategoryTrendContainer.
 */
export default CategoryTrendContainer;
